import Autoplay from "embla-carousel-autoplay";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useMemo, useRef } from "react";

import { Carousel } from "@mantine/carousel";
import {
  Anchor,
  Box,
  Center,
  Container,
  createStyles,
  Flex,
  Group,
  Image,
  Paper,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import FeaturesCards from "../components/FeaturesSection";
import FullWidthImage from "../components/FullWidthImage";
import Layout from "../components/Layout";
import ProjectsSection from "../components/ProjectsSection";
import { IconAxe, IconBuilding, IconWall } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  // title: {
  //   [theme.fn.smallerThan('sm')]: {
  //     fontSize: 24,
  //   },
  // },

  sectionTitle: {
    fontSize: "3rem",
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      transition: "width 0.3s ease-in-out",
      width: 100,
      height: 2,
      marginTop: theme.spacing.sm,
    },
    ".mantine-Paper-root:hover &::after": {
      width: "100%",
    },
    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },
  partnerLogo: {
    filter: "grayscale(50%)",
    transition: "filter 0.3s ease-in-out",
    // height: "100vh!important",
    "&: hover": {
      filter: "grayscale(0%)",
    },
  },
  partnerLogoContainerPattern: {
    backgroundColor: "#f9fbff",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23b28a4f' fill-opacity='0.1'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
}));

// eslint-disable-next-line
export const IndexPageTemplate = ({
  heroImage: heroImg,
  heroImage2: heroImg2,
  heroImage3: heroImg3,
  title,
  description,
  title2,
  description2,
  title3,
  description3,
  featuresTitle,
  featuresDescription,
  partners,
  stats,
}) => {
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  const autoplayPartners = useRef(Autoplay({ delay: 3000 }));
  const heroImage = getImage(heroImg) || heroImg;
  const heroImage2 = getImage(heroImg2) || heroImg2;
  const heroImage3 = getImage(heroImg3) || heroImg3;
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  return (
    <div>
      <Carousel
        onSlideChange={setCurrentSlideIndex}
        withIndicators
        slideSize="100%"
        height="80vh"
        align="start"
        loop
        plugins={[autoplay.current]}
        withControls={false}
        sx={{ borderBottom: "8px solid #b28a4f" }}
      >
        {/* add class active if currentSlideIndex is 0 */}
        <Carousel.Slide className={currentSlideIndex === 0 ? "active" : ""}>
          <FullWidthImage
            img={heroImage}
            title={title}
            description={description}
            imgPosition="center"
          />
        </Carousel.Slide>
        <Carousel.Slide className={currentSlideIndex === 1 ? "active" : ""}>
          <FullWidthImage
            img={heroImage2}
            title={title2}
            description={description2}
            imgPosition="center"
          />
        </Carousel.Slide>
        <Carousel.Slide className={currentSlideIndex === 2 ? "active" : ""}>
          <FullWidthImage
            img={heroImage3}
            title={title3}
            description={description3}
            imgPosition="center"
          />
        </Carousel.Slide>
      </Carousel>

      <Center
        pt="2rem"
        pb="5rem"
        style={{
          background: "linear-gradient(-45deg, #E4F3FB88 0%, #FDF6EE88 100%)",
        }}
      >
        <FeaturesCards
          featuresTitle={featuresTitle}
          featuresDescription={featuresDescription}
          allFeatures={[
            { title, description, icon: "/icons/sketch.png" },
            {
              title: title2,
              description: description2,
              icon: "/icons/sketch.png",
            },
            {
              title: title3,
              description: description3,
              icon: "/icons/sketch.png",
            },
          ]}
        />
      </Center>
      {/* <Box h={8} bg="#b28a4f" /> */}
      {stats && stats.length > 0 && (
        <>
          <Box
          bg="rgba(50, 59, 60, 0.8)"
          >
            <Container size="lg" py="xl">
              {/* 4 Cols (row except in mobile as flex direction column)  Abgeschlossene Projekte: 250+, Mitarbeiter: 40+, Gearbeitet in Städte: 32 etc */}
              <Flex
                sx={(theme) => ({
                  [theme.fn.smallerThan("md")]: {
                    flexDirection: "row",
                    flexWrap: "wrap",
                    paddingTop: "2rem",
                  },
                })}
                direction="row"
                justify="space-around"
                align="center"
              >
                {(stats ?? []).map((stat, i) => (
                  <Flex key={
                    "stat_" + stat.title + i
                  } direction="column" justify="center" align="start" sx={(theme) => ({
                    [theme.fn.smallerThan("md")]: {
                      width: "40%",
                      marginBottom: "2rem",
                    },
                  })}>
                    <Title
                      tt="uppercase"
                      color="#fffb"
                      order={2}
                      ff="Oswald"
                      size={20}
                      ml="sm"
                    >
                      {stat.title}
                    </Title>
                    <Text size={52} color="white" ff="Oswald" ml="sm">
                      {stat.value}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Container>
          </Box>
          <Box h={8} bg="#b28a4f" />
        </>
      )}
      
      <Box pos={"relative"}>
        <Box
          h="100%"
          w="100%"
          top={0}
          pos={"absolute"}
          bottom={0}
          right={0}
          left={0}
          bg="url(/img/wall_background.webp)"
          style={{
            backgroundSize: "cover",
            opacity: 0.15,
            boxShadow:
              "inset 0 0 0 2000px rgba(50, 59, 60, 0.2), inset 0px -100px 100px -100px rgba(50, 59, 60, 0.2)",
          }}
        />

      
        {/* <Container size="lg" bg="#b28a4f" style={{borderRadius: 5}} >
        <Center style={{borderRadius: 5}} py={20}>
        </Center>
      </Container> */}
        <Container px={10} size="lg" pt="7rem">
          <Title
            className={classes.sectionTitle}
            order={1}
            color="rgba(50, 59, 60, 0.8)"
            ff="Oswald"
            ml="sm"
          >
            UNSERE PROJEKTE
          </Title>
          {/* <Box
            w="30rem"
            h={5}
            maw="50%"
            mt="sm"
            mb="lg"
            mx="sm"
            bg={"rgba(50, 59, 60, 0.8)"}
          /> */}
          <ProjectsSection />
          <Title
            className={classes.sectionTitle}
            order={1}
            color="rgba(50, 59, 60, 0.8)"
            ff="Oswald"
            tt={"uppercase"}
            mt="7rem"
            pb="1rem"
            ml="sm"
          >
            Dienstleistungen
          </Title>
        </Container>
      </Box>
      <Box h={8} bg="#b28a4f" />
      {/* with box shadow inset on bottom */}
      <Box
        // h={200}
        style={{
          background:
            "url(https://res.cloudinary.com/europroject-media-cloud-1/image/upload/v1686520628/FooterBild/pexels-media-323780-1677260153060_uqss84_sg29rl.jpg)",
          backgroundPosition: "top",
          filter: "sepia(.4) brightness(1.5)",
          // box shadow inset from fully transparent to opaque
          boxShadow:
          "inset 0 0 0 2000px rgba(0,0,0,.5), inset 0px -100px 100px -100px #000000",
        }}
      >
        <Container size="lg" py="xl">
          {/* <Title
            className={classes.sectionTitle}
            order={1}
            color="rgba(50, 59, 60, 0.8)"
            ff="Oswald"
            ml="sm"
          >
            Dienstleistungen
          </Title> */}
          {/* Three cards with services (title, image, icon, text) */}
          <Flex
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                flexDirection: "column",
                gap: "2rem",
              },
            })}
            direction="row"
            justify="space-between"
            align="stretch"
            gap="lg"
          >
            <Paper bg={"rgba(50, 59, 60, 0.8)"} p="lg" style={{flex: 1}}>
              <Flex direction="column" justify="center" align="center">
                <IconAxe size={50} color="#dddb"/>
                <Title
                  tt="uppercase"
                  color="#dddb"
                  order={2}
                  ff="Oswald"
                  lts={1.2}
                  size={18}
                  mb="md"
                  mt="md"
                >
                  Stahl- und Betonkonstruktionen
                </Title>
                <Text size={18} color="#999" ml="sm">
                  Unsere Fachleute bieten maßgeschneiderte Lösungen für
                  Fundamente, Stützmauern und Trägerstrukturen. Präzision und
                  Einhaltung der Normen stehen bei uns im Fokus.
                </Text>
              </Flex>
            </Paper>
            <Paper bg={"rgba(50, 59, 60, 0.8)"} p="lg" style={{flex: 1}}>
              <Flex direction="column" justify="center" align="center">
                <IconBuilding size={50} color="#dddb" />
                <Title
                  tt="uppercase"
                  color="#dddb"
                  order={2}
                  ff="Oswald"
                  lts={1.2}
                  size={18}
                  mb="md"
                  mt="md"
                >
                  Bewehrungsinstallation
                </Title>
                <Text size={18} color="#999" ml="sm">
                  Unsere Experten sorgen mit bewährten Techniken und
                  hochwertigen Materialien für die Festigkeit und Stabilität
                  Ihrer Betonstrukturen.
                </Text>
              </Flex>
            </Paper>
            <Paper bg={"rgba(50, 59, 60, 0.8)"} p="lg" style={{flex: 1}}>
              <Flex direction="column" justify="center" align="center">
                <IconWall size={50} color="#dddb" />
                <Title
                  tt="uppercase"
                  color="#dddb"
                  order={2}
                  ff="Oswald"
                  lts={1.2}
                  size={18}
                  mb="md"
                  mt="md"
                >
                  Hochwertiges Mauerwerk
                </Title>
                <Text size={18} color="#999" ml="sm">
                  Unsere erfahrenen Maurer liefern exzellentes Mauerwerk, sei es
                  beim Neubau, Verputzen oder der Reparatur von Mauern.
                </Text>
              </Flex>
            </Paper>
          </Flex>
        </Container>
      </Box>
      <Box h={8} bg="#b28a4f" />
      <Box p="3rem 0" pos={"relative"}>
        {/* <Box pos={"absolute"} right={0}  top="20%" w="20%" h="60%" className={classes.partnerLogoContainerPattern} /> */}
        {partners && (
          <Container size="lg" py="xl" style={{ borderRadius: 5 }}>
            <Title
              className={classes.sectionTitle}
              order={1}
              color="rgba(50, 59, 60, 0.8)"
              ff="Oswald"
              ml="sm"
            >
              UNSERE PARTNER
            </Title>

            {/* <Box
            w="30rem"
            h={5}
            maw="50%"
            mt="sm"
            mb="lg"
            mx="sm"
            bg={"rgba(50, 59, 60, 0.8)"}
          /> */}
            <Center py="3rem">
              <Carousel
                withControls={false}
                withIndicators={false}
                loop
                slideSize="13rem"
                align="start"
                slideGap="lg"
                plugins={[autoplayPartners.current]}
              >
                {partners.map((partner, i) => (
                  <Carousel.Slide key={"partner_link" + partner.title + i}>
                    <Tooltip color="blue" withArrow label={partner.title}>
                      <Center w="100%" h="100%">
                        {partner.website ? (
                          <Anchor href={partner.website} target="_blank">
                            <Image
                              src={partner.logoUrl}
                              height="4rem"
                              width="auto"
                              alt={partner.title}
                              className={classes.partnerLogo}
                            />
                          </Anchor>
                        ) : (
                          <Image
                            className={classes.partnerLogo}
                            src={partner.logoUrl}
                            height="4rem"
                            width="auto"
                            alt={partner.title}
                          />
                        )}
                      </Center>
                    </Tooltip>
                  </Carousel.Slide>
                ))}
              </Carousel>
            </Center>
          </Container>
        )}
      </Box>
    </div>
  );
};
IndexPageTemplate.propTypes = {
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroImage2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heroImage3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  title2: PropTypes.string,
  description2: PropTypes.string,
  title3: PropTypes.string,
  description3: PropTypes.string,
  featuresTitle: PropTypes.string,
  featuresDescription: PropTypes.string,
  partners: PropTypes.array,
  stats: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter, heroImage, heroImage2, heroImage3 } =
    data.markdownRemark;

  const partners = useMemo(() => {
    return data.allMarkdownRemark?.edges
      ?.map((edge) => edge.node?.frontmatter)
      ?.filter((x) => x);
  }, [data.allMarkdownRemark?.edges]);

  return (
    <Layout>
      <IndexPageTemplate
        heroImage={heroImage}
        heroImage2={heroImage2}
        heroImage3={heroImage3}
        title={frontmatter.title}
        description={frontmatter.description}
        title2={frontmatter.title2}
        description2={frontmatter.description2}
        title3={frontmatter.title3}
        description3={frontmatter.description3}
        featuresTitle={frontmatter.featuresTitle}
        featuresDescription={frontmatter.featuresDescription}
        partners={[...partners]}
        stats={frontmatter.stats}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      heroImage2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      heroImage3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      heroImage {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      heroImage2 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      heroImage3 {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      frontmatter {
        title
        description
        title2
        description2
        title3
        description3
        featuresTitle
        featuresDescription
        stats {
          title
          value
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "partner-page" }
          featured: { eq: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            logoUrl
            website
          }
        }
      }
    }
  }
`;
