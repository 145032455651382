import { Box, Button, createStyles, Flex, Space } from "@mantine/core";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    overflow: "hidden",
    height: "80vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.5)",
    },
  },
  heroText: {
    // color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[4],
    lineHeight: "1",
    fontSize: "2.0rem",
    fontFamily: 'Kanit',
    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 145,
      height: 2,
      marginTop: theme.spacing.sm,
      marginRight: 'auto',
    },
    // fontSize: "48px", /* adjust font size as needed */
    fontWeight: "bold",
    // color: "#333", /* adjust color as needed */
    // textTransform: "uppercase",
    // backgroundColor: "rgba(255, 255, 255, 0.2)", /* white with 60% opacity */
    borderRadius: "3px",
    // backdropFilter: "blur(10px)",
    // borderBottom: "3px solid rgba(255, 255, 255, 0.4)", 
    // color: "rgba(131, 96, 47, 0.9)",
    ".mantine-Carousel-slide.active:nth-of-type(2) &": 
      {
        color: "rgba(131, 96, 47, 0.9)",
      },
      // ".mantine-Carousel-slide.active:nth-of-type(3) &": 
      // {
      //   color: "rgba(131, 96, 47, 0.9)",
      // },
    color: "#eeea", /* adjust color as needed */
    letterSpacing: "1px", /* add some letter spacing for a more refined look */
    // textShadow: "1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff", /* add a subtle text shadow to give it a more polished look */
     /* blue color from the company logo */
    // color: "#073B4C",
    [theme.fn.largerThan('md')]: {
      fontSize: '3.2rem',
    },
  },
  subHeader: {
    textAlign: 'left',
    fontFamily: 'Catamaran',
    fontSize: '1rem',
    // color: 'darkgray',
    overflowWrap: 'break-word',
    lineHeight: "1.5",
    // backgroundColor: "rgba(255, 255, 255, 0.4)", /* white with 60% opacity */
    // backdropFilter: "blur(10px)",
    padding: "20px",
    borderRadius: "3px",
    display: "none",
    color: "rgba(50, 59, 60, 0.9)", /* adjust color as needed */
    // marginTop: "20px", /* add some space between the title and the text */
    [theme.fn.largerThan('md')]: {
      fontSize: '1.7rem',
      display: "block",
    }
  },
  ctaButton: {
      display: 'inline-block',
      padding: '15px 30px',
      fontSize: '20px',
      fontWeight: 'bold',
      fontFamily: 'Oswald',
      textDecoration: 'none',
      color: '#cdcdcddd',
      // boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
      // backgroundColor: "rgba(131, 96, 47, 0.4)", /* white with 60% opacity */
      // backdropFilter: "blur(10px)",
      marginTop: "20px",
      borderRadius: "0",
      letterSpacing: "2px",
      textTransform: "uppercase",

      transition: 'all 0.3s ease',
    backgroundColor: "#073B4C",
    // color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "#333d",
    },
    [theme.fn.largerThan('md')]: {
      width: '15rem',
      marginLeft: "0.3rem",
      // marginLeft: "auto",
      marginRight: "auto"
    }
  },
  headerTextSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.9)", /* white with 60% opacity */
    // opacity: 0,
    // transition: "all ease-in 1000ms ",
    ".mantine-Carousel-slide.active:first-of-type &": {
      animation: "fade-left-to-right 1s ease-in-out",
      backgroundColor: "rgba(50, 59, 60, 0.9)", /* white with 60% opacity */
      color: "white",
    },
    ".mantine-Carousel-slide.active:nth-of-type(2) &": {
      animation: "fade-right-to-left 2s ease-in-out",
    },
    ".mantine-Carousel-slide.active:nth-of-type(3) &": {
      animation: "fade-right-to-left 2s ease-in-out",
      backgroundColor: "rgba(50, 59, 60, 0.9)", /* white with 60% opacity */
    },
    // clipPath: "inset(10px)",
    // clipPath: "circle(73.6% at 44% 29%)",
    // clipPath: "polygon(0% 0%, 0% 100%, 10% 100%, 9% 9%, 92% 9%, 93% 90%, 3% 91%, 9% 100%, 100% 100%, 100% 0%)",
    keyframes: {
      fadeIn: {
        from: {
          opacity: 0,
          position: "relative",
          left: "100px",

        },
        to: {
          opacity: 1,
          position: "relative",
          left: "0px",
        },
      }
    },

    // backdropFilter: "blur(10px)",
    padding: "20px",
    // borderRadius: "3px",
    marginTop: "20px", /* add some space between the title and the text */
    [theme.fn.largerThan('md')]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
    }
  },
}));


export default function FullWidthImage(props) {
  const {
    height = "80vh",
    img,
    title,
    imgPosition = "center right",
    description
  } = props;
  const { classes, cx } = useStyles();

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
        }}
      >
        {img?.url ? (
          <img
            src={img}
            objectFit={"cover"}
            objectPosition={imgPosition}
            style={{
              gridArea: "1/1",
              filter: "brightness(0.5)",
              // You can set a maximum height for the image, if you wish.
              height: height,
              width: "100%",
            }}
            // This is a presentational image, so the alt should be an empty string
            alt=""
          />
        ) : (
          <GatsbyImage
            image={img}
            objectFit={"cover"}
            objectPosition={imgPosition}
            style={{
              gridArea: "1/1",
              filter: 'brightness(.8)',
              transition: "all ease-in 100ms",
              // You can set a maximum height for the image, if you wish.
              height: height,
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            // aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={["auto", "webp", "avif"]}
          />
        )}
        {(title) && (
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "start",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <Box className={cx(classes.headerTextSection)} display="grid" p="3rem" style={{ placeItems: 'left', }}>
                <h1
                  className={cx(classes.heroText)}
                >
                  {title}
                </h1>
                {/* <h2 className={cx(classes.subHeader)}>
                  {description}
                </h2> */}
                <Space h="xl"/>
                <Link to="/kontakt">
                  <Button className={classes.ctaButton} variant="filled" size="lg">Kontaktiere Uns</Button>
                </Link>

                {/* <ThreeDBuildingModel/> */}
              </Box>
            )}
          </div>
        )}
        {description && (
          <Flex
            align={"center"}
            direction={"row"}
            justify={"center"}
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid", /* white with 60% opacity */
              position: "absolute",
              right: "20px",
              zIndex: "10000",
              height: "100%",
              width: "30%",
            }}
          >
            {/* <Stepper style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', }} p={20} orientation="vertical">
              <Stepper.Step label="Step 1" description={description} />
              <Stepper.Step label="Step 2" description="Verify email" />
              <Stepper.Step label="Step 3" description="Get full access" />
            </Stepper> */}
            {/* <Flex py={20} w="50%" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: '10px', }} justify="center" align="center" direction="column">
              {description && (
                <>
                <Flex justify="center" align="center">
                  <Image src="/icons/sketch.png" width="120px" p={20}  size={712}/>
                </Flex>
                <h2

                  className={cx(classes.subHeader)}
                >
                  {description}
                </h2>
                </>
              )}
            </Flex> */}
            
          </Flex>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
  description: PropTypes.string,
};
