import { Carousel } from "@mantine/carousel";
import {
  Box,
  Button,
  createStyles,
  Space,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Autoplay from "embla-carousel-autoplay";
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useRef } from "react";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    fontFamily: "Catamaran",
    fontSize: "3rem",
    color: "rgba(50, 59, 60, 0.9)",
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",
    fontFamily: "Catamaran",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.fn.largerThan("sm")]: {
      fontSize: "1.8rem",
      marginTop: ".2rem",
    },
  },
  card: {
    height: "25rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "card-show-animation 0.5s ease-in-out",
  },
  ctaButton: {
    // display: 'inline-block',
    // padding: '15px 30px',
    fontSize: ".7rem",
    fontWeight: "bold",
    fontFamily: "Oswald",
    textDecoration: "none",
    color: "#cdcdcddd",
    // boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
    // backgroundColor: "rgba(131, 96, 47, 0.4)", /* white with 60% opacity */
    // backdropFilter: "blur(10px)",
    // marginTop: "20px",
    borderRadius: "0",
    letterSpacing: "2px",
    textTransform: "uppercase",

    transition: "all 0.3s ease",
    backgroundColor: "#073B4C",
    // color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "#333d",
    },
    [theme.fn.largerThan("md")]: {
      // width: '15rem',
      // marginLeft: "0.3rem",
      // marginLeft: "auto",
      // marginRight: "auto"
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: "2rem",
    marginTop: theme.spacing.xs,
  },

  location: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

function Card({ featuredImageUrl, title, location, slug }) {
  const { classes } = useStyles();

  return (
    <Box
      // shadow="xl"
      p="xl"
      m={10}
      // radius="md"
      sx={{
        backgroundImage: `url(${featuredImageUrl})`,
        boxShadow:
          "inset 0 0 0 2000px rgba(0,0,0,.5), 0 0px 5px rgba(0, 0, 0, 0.7)",
      }}
      className={classes.card}
    >
      <Box w="100%" style={{ filter: "brightness:(5.1)" }}>
        <Text className={classes.location} size="xs">
          {location}
        </Text>
        <Title order={3} className={classes.title}>
          {title}
        </Title>
      </Box>
      <Link to={slug}>
        <Button className={classes.ctaButton} variant="filled">
          Öffne Projekt
        </Button>
      <Space h="md" />
      </Link>
      {/* <Button mb="md" variant="filled" color="dark">
        Öffne Projekt
      </Button> */}
    </Box>
  );
}

const ProjectsSection = () => {
  const autoplay = useRef(Autoplay({ delay: 5000 }));
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})px`);
  const data = useGetProjects();
  const { classes } = useStyles();
  const slides = data.map((item, i) => (
    <Carousel.Slide key={item.title + i}>
      <Card {...item} />
    </Carousel.Slide>
  ));
  return (
    <>
      {/* <Title color="dimmed" className={classes.description} align="center" my="md">
            Projekte
        </Title> */}
      {Boolean(slides) && (
        <Carousel
          slideSize={mobile ? "100%" : "33.33%"}
          plugins={[autoplay.current]}
          //   breakpoints={[{ maxWidth: 'sm', slideSize: mobile ? '100%' : '33.33%', slideGap: 2 }]}
          slideGap="md"
          align="start"
          loop
          //   slidesToScroll={}
          withIndicators
        >
          {slides}
        </Carousel>
      )}
    </>
  );
};

export default ProjectsSection;

const useGetProjects = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ProjectsQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "project-page" }
              featured: { eq: true }
            }
          }
          sort: { order: DESC, fields: [frontmatter___date] }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredImageUrl
                location
              }
            }
          }
        }
      }
    `
  );
  const projects = allMarkdownRemark?.edges
    ?.map((edge) => ({
      ...edge?.node?.frontmatter,
      slug: edge?.node?.fields?.slug,
    }))
    .filter((x) => x);
  return projects.length > 5 ? projects : [...projects, ...projects];
};
