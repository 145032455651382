import {
  Card,
  Container,
  createStyles,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import React from "react";
const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    fontFamily: "Catamaran",
    fontSize: "3rem",
    color: "rgba(50, 59, 60, 0.9)",
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",
    fontFamily: "Catamaran",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.fn.largerThan("sm")]: {
      fontSize: "1.8rem",
      marginTop: ".2rem",
    },
  },

  card: {
    borderRadius: 0,
    backgroundColor: "rgba(50, 59, 60, 0.2)",
    animation: "card-show-animation 0.5s ease-in-out",
    "&:hover": {
      // boxShadow: "0 0 0 0.2rem " + theme.fn.primaryColor(),
      backgroundColor: "rgba(50, 59, 60, 0.8)",
      color: "white",
      "& *": {
        color: "white",
      },
    },
    // border: `1px solid ${
    //   theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    // }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      transition: "width 0.3s ease-in-out",
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
    ".mantine-Paper-root:hover &::after": {
      width: "100%",
    },
  },

  bricksPatternBG: {
    backgroundColor: "#f9fbff",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23b28a4f' fill-opacity='0.1'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
}));

function FeaturesCards({ allFeatures, featuresTitle, featuresDescription }) {
  const { classes, theme } = useStyles();
  const [selected, setSelected] = React.useState(0);
  const features = allFeatures.map((feature) => (
    <Card key={feature.title} className={classes.card} p="xl">
      {/* <feature.icon size={50} stroke={2} color={theme.fn.primaryColor()} /> */}
      {/* <Image src={feature.icon} width={50} height={50} /> */}
      <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text size="sm" color="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));
  return (
    <Container
      size="lg"
      // zIndex={10000}
      pos="relative"
    >
      <Title order={2} className={classes.title} align="center" mt="sm">
        {featuresTitle}
      </Title>

      <Text
        color="dimmed"
        className={classes.description}
        align="center"
        mt="md"
      >
        {featuresDescription}
      </Text>
      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}

export default FeaturesCards;
